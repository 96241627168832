import React, {useContext} from "react";
import {graphql, useStaticQuery} from "gatsby";
import {Field, FieldArray, Form, Formik} from "formik";
import Img from "gatsby-image"
import gql from "graphql-tag";
import {Mutation, Query} from "react-apollo";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {client} from '../../apollo/client';
import MyAccountLayout from "../../components/myAccountLayout";
import {UserContext} from "../../context/UserContext";

const UPSERT_STYLE_REPORT = gql`
    mutation Upsert($input:StyleReportInput!) {
        upsertStyleReport(input:$input){
            weight
            styles
        }
    }`

const STYLE_REQUEST = gql`
    query StyleReport {
        styleReport {
            address
            address2
            city
            state
            zip
            country
            phone
            sex
            sizeFeet
            sizeInch
            sizeCm
            weight
            birthdayDay
            birthdayMonth
            birthdayYear
            hairColor
            bodyType
            coatSize
            shoeSize
            pantSize
            shirtSize
            suitSize
            jeansSize
            linkedin
            instagram
            twitter
            pinterest
            remark
            styles
        }
    }
`

const F = ({label, children}) => <div className='pt-4 pb-16 border-gray-500 border-b w-full mr-2'>
  <h4 className='font-sans font-light text-xl'>{label}</h4>
  {children}
</div>

const RadioButton = ({
                       name,
                       value,
                       label,
                       onChange = () => {
                       }
                     }) => <label htmlFor={value}
                                  className={'font-sans font-light text-gray-700 text-xl mr-8'}>
  <Field name={name}
         render={({field, form}) => <input onClick={() => {
           onChange(field, form);
         }
         } id={value} className={'mr-2'} type="radio" {...field}
                                           checked={field.value === value} value={value}/>}/>{label}
</label>

const LabelField = ({name, label, description}) => <div className="w-full my-8">
  <label htmlFor={name}>
    <div className='font-sans font-light text-gray-700 text-xl uppercase tracking-wide'>{label}</div>
    <div className='font-sans font-light text-base text-gray-600'>{description}</div>
    <Field name={name} className={fieldClass + ' w-1/2'}/>
  </label>
</div>

const fieldClass = 'font-sans font-light appearance-none border-gray-500 border-black border-b focus:outline-none text-gray-900'
const labelClass = 'font-sans font-light text-gray-700 text-xl mx-4'
const sizeClass = fieldClass + ' w-64 mr-32 mb-6'

const StyleReport = () => {
  const userContext = useContext(UserContext);
  const data = useStaticQuery(query)
  const p = data.datoCmsStylereportpage

  return <MyAccountLayout>
    <div className='flex flex-col items-start mx-2 md:mx-16'>
      <h1
        className='font-title text-4xl uppercase mt-8 mb-2 text-yellow-700 text-center tracking-wide mb-4'>{p.title}</h1>
      <div
        className="font-sans font-light text-xl">Hello, {userContext.user && userContext.user.idToken.payload.given_name}</div>
      <div className="font-sans font-light text-lg mb-8">{p.description}</div>
      <Query
        client={client}
        query={STYLE_REQUEST}>
        {({loading, data, error}) => {
          if (loading) {
            return <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
          }
          if (error) {
            return <div className="font-sans text-red-700">Something went wrong, please try again later</div>
          }
          return (<Mutation
              client={client}
              mutation={UPSERT_STYLE_REPORT}>
              {(upsertStyleReport) => (<Formik
                  initialValues={data.styleReport || {
                    sex: "f",
                    styles: [],
                    weight: "",
                    sizeFeet: "",
                    sizeInch: "",
                    sizeCm: "",
                    birthdayDay: "",
                    birthdayMonth: "",
                    birthdayYear: "",
                    hairColor: "",
                    bodyType: null,
                    shoeSize: "",
                    coatSize: "",
                    pantSize: "",
                    shirtSize: "",
                    suitSize: "",
                    jeansSize: "",
                    linkedin: "",
                    instagram: "",
                    twitter: "",
                    pinterest: "",
                    remark: "",
                    address: "",
                    address2: "",
                    city: "",
                    state: "",
                    zip: "",
                    country: "",
                    phone: ""
                  }}
                  onSubmit={async ({recaptcha, ...input}, {setSubmitting, setStatus}) => {
                    setStatus(null)
                    console.log(input)
                    delete input['__typename']
                    upsertStyleReport({variables: {input}})
                      .then(r => {
                        setStatus({error: false});
                        setSubmitting(false);
                      })
                      .catch(e => {
                        console.error(e)
                        setStatus({error: true});
                        setSubmitting(false);
                      })
                  }}
                >{({values, isSubmitting, status}) => {
                  return <Form className={'w-full'}>
                    <F label="Are you?">
                      <RadioButton name='sex' value='f' label='Female'
                                   onChange={(field, form) => form.values.styles = []}/>
                      <RadioButton name='sex' value='m' label='Male'
                                   onChange={(field, form) => form.values.styles = []}/>
                    </F>
                    <F label='How tall are you?'>
                      <Field className={fieldClass + ' w-12'} autoFocus={true} name='sizeFeet'/><span
                      className={labelClass}>ft</span>
                      <Field name='sizeInch' className={fieldClass + ' w-12'}/><span className={labelClass}>in</span>
                      <br/>
                      <Field name='sizeCm' className={fieldClass + ' w-12 mt-4'}/><span className={labelClass}>cm</span>
                    </F>
                    <F label="What's your weight?">
                      <Field name='weight' className={fieldClass + ' w-12'}/><span className={labelClass}>lbs.</span>
                    </F>
                    <F label="When is your birthday?">
                      <Field name='birthdayDay' className={fieldClass + ' w-12'} placeholder='DD'/>
                      <Field name='birthdayMonth' className={fieldClass + ' w-12'} placeholder='MM'/>
                      <Field name='birthdayYear' className={fieldClass + ' w-24'} placeholder='YYYY'/>
                    </F>
                    <F label="What's your hair color?">
                      <Field name='hairColor' className={fieldClass + ' w-2/4'}/>
                    </F>
                    <F label="How would you describe your body type">
                      <RadioButton name='bodyType' value='slim' label='Slim'/>
                      <RadioButton name='bodyType' value='average' label='Average'/>
                      <RadioButton name='bodyType' value='athletic' label='Athletic'/>
                      <RadioButton name='bodyType' value='husky' label='Husky'/>
                    </F>
                    <F label="Which size fits you?">
                      <div className="w-2/3 flex flex-wrap">
                        <Field name='coatSize' placeholder='Coat Size' className={sizeClass}/>
                        <Field name='shoeSize' placeholder='Shoe Size' className={sizeClass}/>
                        <Field name='pantSize' placeholder='Pant Size' className={sizeClass}/>
                        <Field name='shirtSize' placeholder='Shirt Size' className={sizeClass}/>
                        <Field name='suitSize' placeholder='Suit Size' className={sizeClass}/>
                        <Field name='jeansSize' placeholder='Jeans Size' className={sizeClass}/>
                      </div>
                    </F>
                    <F label='Which of the styles represent your personality?'>
                      <FieldArray name='styles' render={a => (
                        <div className="flex flex-wrap justify-center">
                          {(values.sex === 'f' ? p.imageswomen : p.imagesmen).map(i => (
                            <div
                              key={i.originalId}
                              className={"m-4"}
                              onClick={() => {
                                const index = values.styles.findIndex(t => t === i.title)
                                if (index === -1) {
                                  a.push(i.title);
                                } else {
                                  a.remove(index)
                                }
                              }}
                            >
                              <div
                                className={"font-sans font-light text-lg text-white px-4 py-2" + (values.styles.includes(i.title) ? ' bg-green-600' : ' bg-black')}>{i.title}</div>
                              <Img fluid={i.fluid} className={'w-64'}/>
                            </div>))}
                        </div>
                      )}/>
                    </F>
                    <F label='Share your social media profiles, so your Stylist can get to know you better.'>
                      <span className="font-sans font-light text-base text-gray-600">This step is optional and confidential. It will only be used for styling purposes.</span>
                      <LabelField name='linkedin' label='LinkedIn profile' description='Ex.: linkedin.com/in/username'/>
                      <LabelField name='instagram' label='Instagram handle' description='Ex.: stitchfixmen'/>
                      <LabelField name='twitter' label='Twitter handle' description='Ex.: stitchfixmen'/>
                      <LabelField name='pinterest' label='Pinterest handle'
                                  description='Paste the full URL of your style board (ex. http://www.pinterest.com/username/your-board).'/>
                    </F>
                    <F label='Anything else your Stylist should know?'>
                      <div className="font-sans font-light text-base text-gray-600">Share more about your lifestyle,
                        physical
                        features or favorite stores
                      </div>
                      <Field rows='7' cols='80' name='remark' component='textarea'
                             className={'font-sans font-light appearance-none border-gray-500 border-black border focus:outline-none text-gray-700'}/>
                    </F>
                    <div className='pt-4 pb-16 border-gray-500 border-b w-full mr-2 flex flex-col'>
                      <h4 className='font-sans font-light text-xl'>Address</h4>
                      <Field name='address' placeholder='Address line 1' className={fieldClass + ' my-2 w-1/2'}/>
                      <Field name='address2' placeholder='Address line 2' className={fieldClass + ' my-2 w-1/2'}/>
                      <div className='w-full flex my-2'>
                        <Field name='country' placeholder='Country' className={fieldClass + ' mr-4'}/>
                        <Field name='city' placeholder='City' className={fieldClass + ' mr-4'}/>
                        <Field name='state' placeholder='State' className={fieldClass}/>
                      </div>
                      <div className='w-full flex my-2'>
                        <Field name='zip' placeholder='Zip code' className={fieldClass + ' mr-4'}/>
                        <Field name='phone' placeholder='Contact number' className={fieldClass}/>
                      </div>
                    </div>
                    <div className="flex mt-8 mb-4 items-center">
                      <button
                        className='text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold py-2 px-8 focus:outline-none focus:shadow-outline uppercase'
                        type="submit" disabled={isSubmitting}>
                        Submit
                      </button>
                      {isSubmitting ?
                        <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/> : null}
                    </div>
                    <span
                      className={'font-sans font-light py-2 px-8 ' + (status ? (status.error ? 'bg-red-300' : 'bg-green-300') : 'invisible')}>{status && status.error ? 'Something went wrong, please try again later' : 'Your style report was saved successfully'}</span>
                  </Form>;
                }}</Formik>
              )}
            </Mutation>
          );
        }}</Query>
    </div>

  </MyAccountLayout>;
}

export default StyleReport

export const query = graphql`
    query StyleReportPage {
        datoCmsStylereportpage {
            title
            description
            imagesmen {
                originalId
                title
                fluid(maxWidth: 300, imgixParams: { fit: "crop", w:"300", h:"300" }) {
                    ...GatsbyDatoCmsFluid
                }
            }
            imageswomen {
                originalId
                title
                fluid(maxWidth: 300, imgixParams: { fit: "crop", w:"300", h:"300" }) {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }
`